<template>
  <b-card class="mb-3" no-body>
    <b-row class="no-gutters">
      <div class="col-md-4 image-container" v-if="cover_url">
        <router-link
          :to="{
            name: 'game',
            params: { identifier: identifier },
          }"
        >
          <img :src="cover_url" class="card-img" :alt="cover_alt" />
        </router-link>
      </div>
      <b-col md="8">
        <b-card-body class="download-card-body">
          <b-card-title title-tag="h5">{{ name }}</b-card-title>
          <b-card-sub-title sub-title-tag="h6" class="mb-2 text-muted"
            >游戏下载</b-card-sub-title
          >
          <b-card-text class="download-card-main-text">
            文件大小:
            <code>{{ formatted_filesize }}</code>
          </b-card-text>
          <DownloadActions :game_info="game_info" />
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import DownloadActions from "./DownloadActions.vue";
import game_info_mixin from "@/mixins/game_info_mixin";

export default {
  name: "DownloadInfoCard",
  props: ["game_info"],
  components: {
    DownloadActions,
  },
  mixins: [game_info_mixin],
};
</script>

<style scoped>
.download-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.download-card-main-text {
  flex-grow: 1;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
</style>
