<template>
  <div id="download">
    <b-container class="mt-3">
      <b-row>
        <b-col sm="8">
          <DownloadElectronCard />
          <download-info-card :game_info="game_info" />
          <dosbox-download-card />
        </b-col>
        <b-col sm="4">
          <SidebarAd :identifier="identifier" />
          <KeymapsCard :game_info="game_info" />
          <CheatsCard :game_info="game_info" />
          <LinksCard :game_info="game_info" />
          <FeedbackCard />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import gameDataJson from "@/utils/info/gameDataJson";
import game_info_mixin from "@/mixins/game_info_mixin";

import DosboxDownloadCard from "./components/DosboxDownloadCard.vue";
import DownloadInfoCard from "./components/DownloadInfoCard.vue";
import DownloadElectronCard from "@/components/ElectronDownload/DownloadElectronCard"

import {
  SidebarAd,
  KeymapsCard,
  CheatsCard,
  LinksCard,
  FeedbackCard
} from "@/components/Sidebar"

export default {
  name: "download",
  mixins: [game_info_mixin],
  components: {
    KeymapsCard,
    CheatsCard,
    LinksCard,
    FeedbackCard,
    SidebarAd,
    DosboxDownloadCard,
    DownloadInfoCard,
    DownloadElectronCard,
  },
  metaInfo() {
    return {
      title: `${this.name}游戏下载`,
      meta: [
        {
          name: "description",
          content: `下载 DOS 游戏 - ${this.name}`,
        },
        {
          name: "keywords",
          content: `${this.name}, 在线, DOS, 游戏, 在线DOS游戏, 下载`,
        },
        {
          property: "og:title",
          content: `${this.name}游戏下载 - 在线 DOS 游戏"`,
        },
        {
          property: "og:url",
          content: this.download_web_url,
        },
        {
          property: "og:description",
          content: `下载 DOS 游戏 - ${this.name}`,
        },
      ],
      link: [{ rel: "canonical", href: this.download_web_url }],
      script: [
        {
          type: "application/ld+json",
          json: [
            this.structured_data,
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "游戏列表",
                  item: "https://dos.zczc.cz/games/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: this.name,
                  item: this.game_web_url,
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: "下载",
                  item: this.download_web_url,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  beforeMount() {
    if (!(this.$route.params.identifier in gameDataJson)) {
      this.$router.replace({ name: "error" });
    }
  },
  computed: {
    game_info() {
      return gameDataJson[this.$route.params.identifier];
    },
  },
};
</script>
