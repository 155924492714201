<template>
  <b-card-text>
    <a
      @click.prevent="handleBinary"
      :href="jsdos_binary_url"
      role="button"
      class="card-link"
      v-if="!downloadFlag"
    >
      下载游戏
    </a>
    <a :href="torrentUrl" class="card-link" download v-if="!downloadFlag">
      下载 BT 种子
    </a>
    <ProgressShow
      @click="downloadBinary"
      :downloadProgress="downloadProgress"
      :customText="progressTextMap"
      role="button"
      v-if="downloadFlag"
    />
  </b-card-text>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";

import ProgressShow from "@/components/ObjectStorage/ProgressShow.vue";
import { ProgressStatusType } from "@/components/ObjectStorage/ProgressStatus";
import {
  getBlob,
  statusType,
  getTorrentUrl,
} from "@/utils/objectStorage";
import { getLogger } from "@/utils/log";
import { fileSave } from "@/utils/fileSave";

export default {
  name: "DownloadActions",
  props: ["game_info"],
  mixins: [game_info_mixin],
  components: {
    ProgressShow,
  },
  data: function () {
    return {
      downloadFlag: false,
      downloadProgress: {
        currentLength: 0,
        totalLength: 0,
        downloadSpeed: 0,
        status: statusType.unstarted,
      },
      blob: undefined,
    };
  },
  methods: {
    handleBinary: async function () {
      this.downloadFlag = true;
      try {
        const blob = await getBlob(
          "dos-bin",
          `${this.identifier}.jsdos.zip`,
          {
            seed: true,
            cache: true,
            webTorrent: true,
          },
          (downloadProgress) => (this.downloadProgress = downloadProgress)
        );
        this.blob = blob;

        this.downloadProgress = {
          currentLength: 1,
          totalLength: 1,
          downloadSpeed: 0,
          status: statusType.success,
        };
        this.logger.log("Download Binary Success");
      } catch (e) {
        this.logger.error("Failed to Fetch Binary");
        this.downloadProgress = {
          currentLength: 1,
          totalLength: 1,
          downloadSpeed: 0,
          status: statusType.error,
        };
      }
    },
    downloadBinary: function () {
      if (this.blob) {
        this.logger.log(
          `Save Binary to Disk, File Name: ${this.identifier}.zip`
        );
        fileSave(this.blob, {
          fileName: `${this.identifier}.zip`,
          extensions: [".zip"],
        });
      }
    },
  },
  computed: {
    torrentUrl: function () {
      return getTorrentUrl("dos-bin", `${this.identifier}.jsdos.zip`);
    },
    progressTextMap: function () {
      return {
        [ProgressStatusType.success]: "下载成功，点击保存",
      };
    },
    logger: function () {
      return getLogger(["binary"]);
    },
  },
};
</script>
