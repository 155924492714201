<template>
  <b-table-lite
    :items="table_items"
    :borderless="true"
    :small="true"
    thead-class="info_table_class"
  >
    <template #cell(html)="data">
      <span v-html="data.value"></span>
    </template>
  </b-table-lite>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";
import { BTableLite } from 'bootstrap-vue'

export default {
  name: "InfoTable",
  props: ["game_info"],
  mixins: [game_info_mixin],
  components: {
    BTableLite,
  },
  computed: {
    table_items() {
      return [
        { key: "发行年份", html: this.release_year || "暂无数据" },
        { key: "繁体名称", html: this.zh_Hant_name || "暂无数据" },
        { key: "英文名称", html: this.en_name || "暂无数据" },
        {
          key: "下载链接",
          html:
            `<a href="${this.binary_url}" targe="_blank">${this.binary_url}</a>` ||
            "暂无链接",
        },
        { key: "文件大小", html: `<code>${this.formatted_filesize}</code>` },
        { key: "启动命令", html: `<code>${this.executable}</code>` },
      ];
    },
  },
};
</script>

<style>
.info_table_class {
  display: none;
}
</style>
