<template>
  <a :href="link" download>Windows 版本</a>
</template>

<script>
import { getOriginalWebUrl } from "@/utils/objectStorage";

export default {
  name: "DosBoxWindowsLink",
  computed: {
    link: () =>
      getOriginalWebUrl(
        "dos-electron-assets",
        "dosbox/DOSBox0.74-3-win32-installer.exe"
      ),
  },
};
</script>
