<template>
  <a :href="link" download>macOS 版本</a>
</template>

<script>
import { getOriginalWebUrl } from "@/utils/objectStorage";

export default {
  name: "DosBoxMacOSLink",
  computed: {
    link: () =>
      getOriginalWebUrl("dos-electron-assets", "dosbox/DOSBox-0.74-3-3.dmg"),
  },
};
</script>
