<template>
  <b-card class="mb-3" no-body>
    <b-row class="no-gutters">
      <b-col md="4" class="image-container">
        <img
          :src="require('@/assets/img/gamepad.svg')"
          class="card-img website-icon"
          alt="Website Icon"
        />
      </b-col>
      <b-col md="8">
        <b-card-body class="download-card-body">
          <b-card-title title-tag="h5"
            >在线 DOS 游戏桌面版<b-badge class="ml-1" variant="success"
              >推荐</b-badge
            ></b-card-title
          >
          <b-card-text class="download-card-main-text">
            <ul>
              <li>自动下载游戏与 DOSBox</li>
              <li>免除 DOSBox 的繁琐配置</li>
              <li>与网页版存档互通<small>（即将推出）</small></li>
            </ul>
          </b-card-text>
          <b-card-text>
            <router-link :to="{ name: 'desktopDownload' }" class="card-link">
              前往下载
            </router-link>
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BBadge } from "bootstrap-vue";

export default {
  name: "DownloadElectronCard",
  components: {
    BBadge,
  },
};
</script>

<style>
.download-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.download-card-main-text {
  flex-grow: 1;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.website-icon {
  padding: 10px;
}
</style>