<template>
  <b-card class="mb-3" no-body>
    <b-row class="no-gutters">
      <b-col md="4" class="image-container">
        <img
          :src="require('@/assets/img/dosbox_icon.png')"
          class="card-img"
          alt="DOXBox Icon"
        />
      </b-col>
      <b-col md="8">
        <b-card-body>
          <b-card-title title-tag="h5">DOSBox 0.74-3</b-card-title>
          <b-card-sub-title sub-title-tag="h6" class="mb-2 text-muted"
            >软件下载</b-card-sub-title
          >
          <b-card-text>
            <ul>
              <li>
                <DosBoxWindowsLink />
              </li>
              <li>
                <DosBoxMacOSLink />
              </li>
            </ul>
            官网链接:
            <a href="https://www.dosbox.com" target="_blank" rel="noopener"
              >https://www.dosbox.com</a
            ><br />
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import DosBoxWindowsLink from "./DosBoxWindowsLink.vue";
import DosBoxMacOSLink from "./DosBoxMacOSLink.vue";

export default {
  name: "DosboxDownloadCard",
  components: {
    DosBoxWindowsLink,
    DosBoxMacOSLink,
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
</style>
