<template>
  <b-card class="mb-3" no-body>
    <b-row class="no-gutters">
      <div class="col-md-4 info-img" v-if="cover_url">
        <router-link
          :to="{
            name: 'game',
            params: { identifier: identifier },
          }"
        >
          <img :src="cover_url" class="card-img" :alt="cover_alt" />
        </router-link>
      </div>
      <b-col md="8">
        <b-card-body>
          <b-card-title title-tag="h4">{{ name }}</b-card-title>
          <b-card-text>
            <info-table :game_info="game_info" />
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";
import InfoTable from "./InfoTable.vue";

export default {
  name: "InfoMainCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
  components: { InfoTable },
};
</script>


<style scoped>
.info-img {
  display: flex;
  align-items: center;
}
</style>
